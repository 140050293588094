import { LocalKey } from 'constants/local-storage';

import { loadState, removeState, saveState } from 'utils/localStorage';

import { Nullable } from 'types/common';

export const useLocalStorage = <T>(localKey: LocalKey) => {
  const setValue = (newValue: T) => {
    saveState(localKey, newValue);
  };

  const getValue = (): Nullable<T> => {
    return loadState<T>(localKey);
  };

  const removeValue = () => {
    removeState(localKey);
  };

  return {
    setValue,
    getValue,
    removeValue,
  };
};
