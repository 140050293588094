import * as React from 'react';

import styles from './styles.module.scss';

const MobilePlug: React.FC = () => {
  return (
    <p className={styles.mobilePlug}>
      Перейдите с мобильной на десктопную версию
    </p>
  );
};

export default MobilePlug;
