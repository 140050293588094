import * as React from 'react';
import { useSelector } from 'react-redux';

import { clearAllStatistics } from 'reducers/statisticsSlice';
import { RootState, useAppDispatch } from 'store';
import { useGetClientQuery } from 'store/api';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import useModal from 'hooks/useModal.hook';

import PsychotypeInformation from 'components/psychotype/components/information';
import { PsychotypeInformationContainer } from 'components/psychotype/components/information/type';
import PsychotypeInformationInitial from 'components/psychotype/components/information-initial';

import AccentIcon from 'ui-kit/accent-icon';
import Button from 'ui-kit/button';
import { TypeButton, VariantButton } from 'ui-kit/button/type';
import { PresentationFinishIcon, PresentationStartIcon } from 'ui-kit/icons';
import Modal from 'ui-kit/modal';

import { lexics } from 'data/lexics';

import { LocalKey } from 'constants/local-storage';

import { Links } from 'types/links';
import { NamesModal } from 'types/modals';
import { VisitClientInformation } from 'types/statistics';

import { handleStartVisit } from './helper';

import styles from './presentations.module.scss';

const ModalStartVisit: React.FC = () => {
  const router = useCustomRouter();

  const { handleCloseModal } = useModal();

  return (
    <Modal name={NamesModal.presentation__start}>
      <section className={styles.modalContent}>
        <AccentIcon icon={<PresentationStartIcon />} />

        <h2 className={styles.modalTitle}>
          {lexics.segmentation.modals.visit_start.no_stats.title}
        </h2>

        <span className={styles.modalDescription}>
          {lexics.segmentation.modals.visit_start.no_stats.description}
        </span>

        <div className={styles.modalButtons}>
          <Button type={TypeButton.submit} onClick={handleStartVisit(router)}>
            {lexics.segmentation.modals.visit_start.button_start}
          </Button>
          <Button
            variant={VariantButton.outlined}
            type={TypeButton.button}
            onClick={handleCloseModal(NamesModal.presentation__start)}>
            Нет
          </Button>
        </div>
      </section>
    </Modal>
  );
};

const ModalStartStatisticVisit: React.FC = () => {
  const router = useCustomRouter();

  const { handleCloseModal } = useModal();

  return (
    <Modal name={NamesModal.presentation__start_stats}>
      <section className={styles.modalContent}>
        <AccentIcon icon={<PresentationStartIcon />} />

        <h2 className={styles.modalTitle}>
          {lexics.segmentation.modals.visit_start.stats.title}
        </h2>
        <div className={styles.modalButtons}>
          <Button type={TypeButton.submit} onClick={handleStartVisit(router)}>
            {lexics.segmentation.modals.visit_start.button_start}
          </Button>
          <Button
            variant={VariantButton.outlined}
            type={TypeButton.button}
            onClick={handleCloseModal(NamesModal.presentation__start_stats)}>
            Нет
          </Button>
        </div>
      </section>
    </Modal>
  );
};

const ModalFinishVisit: React.FC = () => {
  const { push } = useCustomRouter();

  const dispatch = useAppDispatch();

  const { removeValue: removeLocalStatistics } = useLocalStorage(
    LocalKey.statistics,
  );

  const { removeValue: removeStatsId } = useLocalStorage(LocalKey.backup_data);

  const { handleCloseModal } = useModal();

  const handleGoHome = () => {
    dispatch(clearAllStatistics(null));
    removeLocalStatistics();
    removeStatsId();
    push(Links.default);
  };

  return (
    <Modal name={NamesModal.presentation__finish}>
      <section className={styles.modalContent}>
        <AccentIcon icon={<PresentationFinishIcon />} />

        <h2 className={styles.modalTitle}>
          {lexics.segmentation.modals.visit_finish.title}
        </h2>
        <div className={styles.modalButtons}>
          <Button type={TypeButton.submit} onClick={handleGoHome}>
            Да
          </Button>
          <Button
            variant={VariantButton.outlined}
            type={TypeButton.button}
            onClick={handleCloseModal(NamesModal.presentation__finish)}>
            Нет
          </Button>
        </div>
      </section>
    </Modal>
  );
};

const ModalSegmentation: React.FC = () => {
  const { clientId, isUnknownClient } = useSelector<
    RootState,
    VisitClientInformation
  >((state) => state.statistics.clientInformation);

  const { data: client } = useGetClientQuery(clientId, {
    skip: !clientId || isUnknownClient,
  });

  const { handleCloseModal } = useModal();

  const psychotype = client?.psychotype ?? [];

  const isPsychotypeInformation = psychotype.length > 0 && !isUnknownClient;

  return (
    <Modal name={NamesModal.presentation__segmentation}>
      <section className={styles.modalContent}>
        {isPsychotypeInformation ? (
          <PsychotypeInformation
            container={PsychotypeInformationContainer.modal}
            psychotypes={psychotype}
          />
        ) : (
          <PsychotypeInformationInitial />
        )}

        <Button
          className={styles.buttonGood}
          type={TypeButton.submit}
          onClick={handleCloseModal(NamesModal.presentation__segmentation)}>
          {lexics.button_good}
        </Button>
      </section>
    </Modal>
  );
};

export default [
  ModalStartVisit,
  ModalStartStatisticVisit,
  ModalFinishVisit,
  ModalSegmentation,
];
