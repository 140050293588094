import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from 'types/common';

export type ErrorType = {
  error: string;
  message: string;
  statusCode: number;
};
export type ErrorState = {
  globalError?: Nullable<number>;
};

const initialState: ErrorState = {
  globalError: null,
};
export const isCriticalError = (code: Nullable<number> | undefined) => {
  const criticalCodes = [500, 502, 503, 504];
  return code && criticalCodes.includes(code);
};

export const error = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setGlobalError(state, action: PayloadAction<Nullable<number> | undefined>) {
      state.globalError = isCriticalError(action.payload)
        ? action.payload
        : null;
    },
  },
});

export const { setGlobalError } = error.actions;
export default error.reducer;
