import { StylesConfig } from 'react-select';

import { Option } from './type';

export const stylesSelect: StylesConfig<Option> = {
  control: (styles) => ({
    ...styles,
    position: 'relative',
    appearance: 'none',
    backgroundColor: 'var(--background-color)',
    height: 54,
    width: '100%',
    padding: '0 16px',
    lineHeight: '1.3',
    border: 'unset',
    borderBottom: '2px solid var(--bottom-color)',
    borderRadius: 'var(--input-radius)',
    transition: '0.4s',
    boxShadow: 'inset 0 0 0 1px var(--shadow-color)',
    cursor: 'pointer',

    ':hover': {
      borderBottom: '2px solid var(--bottom-color)',
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: 6,
    borderRadius: 'var(--input-radius)',
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
    maxHeight: 180,
    fontFamily: 'RocheSans',
    backgroundColor: 'var(--background-color)',
    fontSize: 'var(--font-size-xs)',
    borderRadius: 'var(--input-radius)',
    '::-webkit-scrollbar': {
      width: 15,
    },
    '::-webkit-scrollbar-thumb ': {
      backgroundClip: 'content-box',
      backgroundColor: 'var(--primary-accent-color)',
      border: '4px solid transparent',
      borderRadius: 50,
    },
  }),
  input: (styles) => ({
    ...styles,
    color: 'var(--primary-text-color)',
  }),

  option: (styles) => ({
    ...styles,
    color: 'var(--grey-light-color)',
    backgroundColor: 'var(--background-color)',
    cursor: 'pointer',
    opacity: 0.5,

    ':not(:last-child)': {
      borderBottom: '1px solid var(--grey-light-color)',
    },

    ':hover': {
      backgroundColor: 'white',
      opacity: 1,
    },
  }),
  singleValue: (style) => ({
    ...style,
    padding: 0,
    margin: 0,
    color: 'var(--primary-text-color)',
  }),
};

export const stylesSortSelect: StylesConfig<Option> = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    position: 'relative',
    appearance: 'none',
    backgroundColor: 'var(--background-color)',
    minHeight: 32,
    padding: '0 12px',
    opacity: isDisabled ? 0.6 : 1,
    lineHeight: '1.3',
    border: 'unset',
    borderRadius: 6,
    transition: '0.4s',
    boxShadow: 'inset 0 0 0 1px var(--shadow-color)',
    cursor: 'pointer',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: 6,
    borderRadius: 'var(--input-radius)',
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
    maxHeight: 180,
    fontFamily: 'RocheSans',
    backgroundColor: 'var(--background-color)',
    fontSize: 'var(--font-size-xs)',
    borderRadius: 'var(--input-radius)',
    '::-webkit-scrollbar': {
      width: 15,
    },
    '::-webkit-scrollbar-thumb ': {
      backgroundClip: 'content-box',
      backgroundColor: 'var(--primary-accent-color)',
      border: '4px solid transparent',
      borderRadius: 50,
    },
  }),
  input: (styles) => ({
    ...styles,

    margin: 0,
    padding: 0,

    color: 'var(--primary-text-color)',
  }),
  placeholder: (styles) => ({
    ...styles,

    fontSize: 12,
  }),
  option: (styles) => ({
    ...styles,

    color: 'var(--grey-light-color)',
    backgroundColor: 'var(--background-color)',

    fontSize: 12,

    cursor: 'pointer',

    opacity: 0.5,

    ':not(:last-child)': {
      borderBottom: '1px solid var(--grey-light-color)',
    },

    ':hover': {
      backgroundColor: 'white',
      opacity: 1,
    },
  }),
  singleValue: (style) => ({
    ...style,
    padding: 0,
    margin: 0,

    fontSize: 12,

    color: 'var(--grey-light-color)',
  }),
};
