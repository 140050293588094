import {
  ActionCreatorWithPayload,
  AnyAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import {
  ErrorState,
  ErrorType,
  setGlobalError,
} from 'reducers/globalErrorSlice';

import { Nullable } from 'types/common';

export const toUpperFirstLetter = (string: string): string => {
  const suggestions = string
    .split('.')
    .map((sug) => sug[0]?.toUpperCase() + sug.slice(1))
    .join('. ');
  return suggestions;
};

export const handleGlobalError = (
  error: AxiosError | unknown,
  dispatch: ThunkDispatch<
    {
      error: ErrorState;
    },
    unknown,
    AnyAction
  >,
  setLocalError: ActionCreatorWithPayload<Nullable<ErrorType>>,
) => {
  const err = error as AxiosError;
  const errorData = err.response?.data;
  dispatch(setGlobalError(err.response?.status));
  dispatch(setLocalError(errorData));
};
