import { FC } from 'react';
import uuid from 'react-uuid';

import ModalsPresentations from './components/presentations';

const AllModals: FC = () => {
  const modals = [ModalsPresentations];

  return (
    <>
      {modals.map((modal) => {
        return modal.map((Modal) => {
          return <Modal key={uuid()} />;
        });
      })}
    </>
  );
};

export default AllModals;
