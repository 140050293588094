import {
  createAction,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

import { BasicPsychotype } from 'ui-kit/icons/types';

import { unknownClientValue } from 'constants/client';
import { LocalKey } from 'constants/local-storage';

import { loadState } from 'utils/localStorage';

import { Nullable } from 'types/common';
import {
  Coordinates,
  VisitClientInformation,
  VisitErrors,
  VisitPresentationStatistics,
  VisitSlidesStatistics,
} from 'types/statistics';

export const setUnknownClient = createAction('setUnknownClient');

export interface Answer {
  id: string;
  value: BasicPsychotype;
}

export interface StatisticsState {
  clientInformation: VisitClientInformation;

  clientAnswers: Answer[];
  presentationInformation: VisitPresentationStatistics;
  slidesInformation: VisitSlidesStatistics[];

  isShowTopContainer: boolean;
}

const initialVisitErrors: VisitErrors = {
  client: false,
  clientTag: false,
  organisationTag: false,
};

export const initialClientInformation: VisitClientInformation = {
  presentationId: '',
  cityId: '',
  organisationId: '',
  organisationType: '',
  visitTypeId: '',
  clientId: '',
  isUnknownClient: false,
  isOrganisationRetailType: false,
  clientTag: '',
  organisationTag: '',
  errors: initialVisitErrors,
};

const initialPresentationInformation: VisitPresentationStatistics = {
  gpsCoordinates: {},
};

const initialState: StatisticsState = {
  clientInformation: initialClientInformation,
  presentationInformation: initialPresentationInformation,
  clientAnswers: [],
  slidesInformation: [],
  isShowTopContainer: false,
};

const getInitialState = (): StatisticsState => {
  const storageState = loadState<StatisticsState>(LocalKey.statistics);

  return storageState ? storageState : initialState;
};

export const statisticsSlice = createSlice<
  StatisticsState,
  SliceCaseReducers<StatisticsState>
>({
  name: 'statistics',
  initialState: getInitialState(),
  reducers: {
    setPresentationId(state, { payload }: PayloadAction<string>) {
      state.clientInformation.presentationId = payload;
    },

    setOrganizationId(state, { payload }: PayloadAction<string>) {
      const { organisationId } = state.clientInformation;

      const isSaveClientId =
        payload === organisationId ||
        state.clientInformation.isOrganisationRetailType;

      const clientId = isSaveClientId ? state.clientInformation.clientId : '';

      const isUnknownClient = isSaveClientId
        ? state.clientInformation.isUnknownClient
        : false;

      state.clientInformation = {
        ...state.clientInformation,
        organisationId: payload,
        clientId,
        isUnknownClient,
      };
    },

    setClientId(state, { payload }: PayloadAction<string>) {
      state.clientInformation.clientId = payload;

      state.clientInformation.isUnknownClient = false;
    },

    setCityId(state, { payload }: PayloadAction<string>) {
      const isClearClientId = !state.clientInformation.isOrganisationRetailType;

      if (isClearClientId) {
        state.clientInformation.clientId = '';
      }

      state.clientInformation.cityId = payload;
      state.clientInformation.organisationId = '';
    },

    setAnswer(state, { payload }: PayloadAction<Answer>) {
      const { id } = payload;

      let newAnswers = [...state.clientAnswers];

      const answer = newAnswers.find((answer) => answer.id === id);

      if (!answer) {
        newAnswers.push(payload);
      } else {
        newAnswers = newAnswers.map((answer) => {
          if (answer.id === id) {
            return payload;
          }
          return answer;
        });
      }

      state.clientAnswers = newAnswers;
    },

    setAnswers(state, { payload }: PayloadAction<Answer[]>) {
      state.clientAnswers = payload;
    },

    clearClientInformation(state) {
      state.clientInformation = initialClientInformation;
      state.clientAnswers = [];
    },

    clearPresentationInformation(state) {
      state.presentationInformation = initialPresentationInformation;
      state.slidesInformation = [];
    },

    setPresentationInformation(
      state,
      { payload }: PayloadAction<VisitPresentationStatistics>,
    ) {
      state.presentationInformation = payload;
    },

    addSlide(
      state,
      { payload: newSlide }: PayloadAction<VisitSlidesStatistics>,
    ) {
      const copySlidesInformation = [...state.slidesInformation];

      copySlidesInformation.push(newSlide);

      // Предпоследний слайд
      const penultSlide =
        copySlidesInformation[copySlidesInformation.length - 2];

      // Логика сохранения времени конца предпоследнего слайда
      if (penultSlide) {
        // Время предпоследнего слайда
        const copyPenultTimestamp = [...penultSlide.timestamp];

        // Последнее время в предпоследнем слайде
        const penultSlideLastTimestamp =
          copyPenultTimestamp[copyPenultTimestamp.length - 1];

        if (penultSlideLastTimestamp) {
          copyPenultTimestamp.splice(-1, 1, {
            ...penultSlideLastTimestamp,
            endVisit: newSlide.timestamp[0].startVisit,
          });

          copySlidesInformation.splice(-2, 1, {
            ...penultSlide,
            timestamp: copyPenultTimestamp,
          });
        }
      }

      state.slidesInformation = copySlidesInformation;
    },

    setSlides(state, { payload }: PayloadAction<VisitSlidesStatistics[]>) {
      state.slidesInformation = payload;
    },

    addClickCoordinatesOnSlide(state, { payload }: PayloadAction<Coordinates>) {
      const { slidesInformation } = state;

      const lastIndex = slidesInformation.length - 1;

      const lastSlide = slidesInformation[lastIndex];

      slidesInformation[lastIndex] = {
        ...slidesInformation[lastIndex],
        coordinates: [...(lastSlide ? lastSlide.coordinates : []), payload],
      };

      state.slidesInformation = slidesInformation;
    },

    toggleShowTopContainer(
      state,
      { payload }: PayloadAction<Nullable<boolean>>,
    ) {
      if (payload === null) {
        state.isShowTopContainer = !state.isShowTopContainer;
      } else {
        state.isShowTopContainer = payload;
      }
    },

    clearAllStatistics(state) {
      state.clientAnswers = initialState.clientAnswers;
      state.clientInformation = initialState.clientInformation;
      state.isShowTopContainer = initialState.isShowTopContainer;
      state.presentationInformation = initialState.presentationInformation;
      state.slidesInformation = initialState.slidesInformation;
    },

    setOrganisationType(state, { payload }: PayloadAction<string>) {
      state.clientInformation.organisationType = payload;

      state.clientInformation.organisationId = '';
    },

    setVisitTypeId(state, { payload }: PayloadAction<string>) {
      state.clientInformation.visitTypeId = payload;
    },

    setIsOrganisationRetailType(state, { payload }: PayloadAction<boolean>) {
      state.clientInformation.isOrganisationRetailType = payload;
    },

    setClientTag(state, { payload }: PayloadAction<string>) {
      state.clientInformation.clientTag = payload;
    },
    clearClientTag(state) {
      state.clientInformation.clientTag = initialClientInformation.clientTag;
    },

    setOrganisationTag(state, { payload }: PayloadAction<string>) {
      state.clientInformation.organisationTag = payload;
    },
    clearOrganisationTag(state) {
      state.clientInformation.organisationTag =
        initialClientInformation.organisationTag;
    },

    setVisitErrors(state, { payload }: PayloadAction<Partial<VisitErrors>>) {
      state.clientInformation.errors = {
        ...state.clientInformation.errors,
        ...payload,
      };
    },

    clearVisitErrors(state) {
      state.clientInformation.errors = initialVisitErrors;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(setUnknownClient, (state) => {
      state.clientInformation.clientId = unknownClientValue;

      state.clientInformation.isUnknownClient = true;
    });
  },
});

export const {
  setPresentationId,
  setOrganizationId,
  setCityId,
  setClientId,
  clearClientInformation,
  clearPresentationInformation,
  setAllPsychotype,
  setClientComments,
  toggleShowTopContainer,
  setAnswer,
  setAnswers,
  addSlide,
  setSlides,
  addClickCoordinatesOnSlide,
  setPresentationInformation,
  clearAllStatistics,
  setOrganisationType,
  setVisitTypeId,
  setIsOrganisationRetailType,

  setClientTag,
  clearClientTag,

  setOrganisationTag,
  clearOrganisationTag,

  setVisitErrors,
  clearVisitErrors,
} = statisticsSlice.actions;

export default statisticsSlice.reducer;
