import Router from 'next/router';

import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  retry,
} from '@reduxjs/toolkit/query/react';
import * as Sentry from '@sentry/nextjs';

import { setOrganizationInformation } from 'reducers/organizationSlice';
import {
  setAnswers,
  setClientId,
  setPresentationId,
  setPresentationInformation,
  setSlides,
} from 'reducers/statisticsSlice';
import store from 'store';

import { ClientComments } from 'components/segmentation/components/comments/type';

import {
  createToastError,
  createToastSuccess,
} from 'ui-kit/alert-info/controls';

import { lexics } from 'data/lexics';

import { LocalKey } from 'constants/local-storage';

import { getSegmentationLink } from 'utils/link';
import { saveState } from 'utils/localStorage';
import { constructCustomStyles } from 'utils/style';

import { ClientInformation, ClientsInformation } from 'types/clients';
import { Nullable } from 'types/common';
import { OrganizationType } from 'types/organization';
import {
  OrganizationInformation,
  OrganizationsInformation,
} from 'types/organizations';
import { Presentation, Presentations, Slide } from 'types/presentation';
import { User } from 'types/user';

import {
  CityResponse,
  CreateStatisticsRequest,
  CreateStatisticsResponse,
  GetClientOrganisationTypeAllRequest,
  GetClientOrganisationTypeAllResponse,
  GetClientOrganizationResponse,
  GetClientOrganizationsRequest,
  GetTagsRequest,
  GetTagsResponse,
  GetVisitTypesRequest,
  GetVisitTypesResponse,
  OrganisationsResponse,
  PostCommentsResponseType,
  QueryParameters,
} from './type';

const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API_URL,
    credentials: 'include',
  }),
  { maxRetries: 0 },
);

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  const error = result.error as FetchBaseQueryError & {
    error?: string;
  };

  const unauthError = error && error.status === 401;

  const isNoInternet =
    error && /Network Error|Failed to fetch/.test(error.error ?? '');

  if (isNoInternet) {
    createToastError(lexics.api.response.default.error);
  }

  if (unauthError) {
    window.location.assign(
      `${process.env.NEXT_PUBLIC_ID_URL}?redirect=${window.location.host}`,
    );
  }

  return result;
};

const api = createApi({
  tagTypes: [
    'Presentation',
    'Presentations',
    'Slides',
    'Client',
    'ClientComments',
    'Clients',
    'Organization',
    'Organizations',
    'ClientOrganization',
    'ClientOrganizations',
    'User',
    'Cities',
    'Tags',
  ],
  keepUnusedDataFor: 86_400,
  refetchOnReconnect: true,
  reducerPath: 'mainApi',
  baseQuery: baseQueryWithReauth,
  endpoints: ({ query, mutation }) => ({
    getOrganization: query<OrganizationType, string | undefined>({
      query: (name) => `organisations/${name}`,
      transformResponse: (data: OrganizationType) => {
        const domains = data.allowedEmailDomains
          ? [...data.allowedEmailDomains, 'gmail.com', 'post-scriptum.ru']
          : ['gmail.com', 'post-scriptum.ru'];

        return {
          ...data,
          allowedEmailDomains: domains,
        };
      },
      providesTags: (_, __, arg) => [{ type: 'Organization', id: arg }],
    }),

    getPresentations: query<Presentations[], Nullable<QueryParameters>>({
      query: (arg) => {
        if (!arg) {
          return 'detailing-presentations/all';
        }

        const { sort, order, text } = arg;

        const params = {
          ...(sort && { sort }),
          ...(order && { order }),
          ...(text && { text }),
        };

        return {
          url: `detailing-presentations/all`,
          params,
        };
      },
      providesTags: (_, __, arg) => [
        {
          type: 'Presentations',
          sort: arg?.sort,
          order: arg?.order,
          text: arg?.text,
        },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            dispatch(api.util.invalidateTags(['Presentation']));
          }
        } catch (e) {}
      },
    }),

    getPresentation: query<Presentation, string | undefined>({
      query: (id) => `detailing-presentations/${id}`,
      providesTags: (_, __, arg) => [{ type: 'Presentation', id: arg }],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data && arg) {
            dispatch(setPresentationId(arg));
          }
        } catch {}
      },
    }),

    getPresentationSlides: query<Slide[], string | undefined>({
      query: (id) => `detailing-slides/${id}`,
      providesTags: (_, __, arg) => [
        {
          type: 'Slides',
          id: arg,
        },
      ],
    }),

    getClientOrganizations: query<
      OrganizationsInformation[],
      Nullable<QueryParameters>
    >({
      query: (arg) => {
        return {
          url: `client-organisations/all`,
          params: arg || {},
        };
      },

      providesTags: (_, __, arg) => [
        {
          type: 'ClientOrganizations',
          sort: arg?.sort,
          order: arg?.order,
          text: arg?.text,
          clientTypeId: arg?.clientTypeId,
        },
      ],
    }),

    getClientOrganization: query<
      GetClientOrganizationResponse,
      GetClientOrganizationsRequest
    >({
      query: (id) => `client-organisations/${id}`,

      providesTags: (_, __, arg) => [{ type: 'ClientOrganization', id: arg }],
    }),

    getClientOrganizationVisit: query<OrganizationInformation, string>({
      query: (id) => `client-organisations/visit/${id}`,

      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          Sentry.withScope((scope) => {
            scope.setTag('Point', 'client-organisations/visit/[id]');

            scope.setLevel('warning');

            Sentry.captureException(new Error(`clientOrganization ID: ${arg}`));
          });
        }
      },

      providesTags: (_, __, arg) => [{ type: 'ClientOrganization', id: arg }],
    }),

    getCities: query<CityResponse, string>({
      query: (id) => {
        return `cities/get-all/${id}`;
      },
      providesTags: [{ type: 'Cities' }],
    }),

    getOrganisations: query<
      OrganisationsResponse,
      {
        cityId: string;
        clientTypeId: string;
      }
    >({
      query: ({ cityId, clientTypeId }) => {
        return {
          url: `cities/get-one/${cityId}`,
          params: {
            clientTypeId,
          },
        };
      },

      providesTags: [{ type: 'Organizations' }],
    }),

    getUser: query<User, void>({
      query: () => 'user',

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: user } = await queryFulfilled;

          const { organisation, email, id, firstName, secondName } = user;

          const { gradientType, colors } = organisation;

          const gradient = gradientType?.[0] ?? '';

          constructCustomStyles(gradient, colors);

          dispatch(setOrganizationInformation(organisation));

          Sentry.setUser({
            email,
            name: `${firstName} ${secondName}`,
            id,
          });
        } catch {}
      },

      providesTags: [{ type: 'User' }],
    }),

    getClients: query<ClientsInformation[], QueryParameters>({
      query: ({ sort, order, text }) =>
        `clients/all?sort=${sort}&order=${order}&text=${text}`,
      providesTags: (_, __, arg) => [
        {
          type: 'Clients',
          sort: arg.sort,
          order: arg.order,
          text: arg.text,
        },
      ],
    }),

    getClient: query<ClientInformation, string | undefined>({
      query: (id) => `clients/${id}`,

      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            const { id } = data;

            dispatch(setClientId(id));
          }
        } catch {}
      },

      providesTags: (_, __, arg) => [{ type: 'Client', id: arg }],
    }),

    updateClient: mutation<
      ClientInformation,
      {
        id: string | undefined;
        body: Pick<ClientInformation, 'psychotype'>;
      }
    >({
      query: ({ id, body }) => {
        return {
          url: `clients/${id}`,
          method: 'PATCH',
          body,
        };
      },

      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            createToastSuccess(lexics.api.response.updateClient.success);
          }
        } catch {
          createToastError(lexics.api.response.updateClient.error.default);
        } finally {
          dispatch(setAnswers([]));
        }
      },

      extraOptions: {
        maxRetries: 5,
      },

      invalidatesTags: ['Client', 'ClientOrganization'],
    }),

    createStatistics: mutation<
      CreateStatisticsResponse,
      CreateStatisticsRequest
    >({
      query: (body) => {
        return {
          url: 'detailing-stats/create',
          method: 'POST',
          body,
        };
      },

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { presentationId } =
          store.getState().statistics.clientInformation;

        try {
          const { data } = await queryFulfilled;

          const { statsId } = data;

          const { slides, gpsCoordinates } = arg;

          dispatch(setPresentationInformation({ gpsCoordinates }));
          dispatch(setSlides(slides));

          if (!statsId) {
            createToastError(
              lexics.api.response.createStatistics.error.default,
            );
          } else {
            saveState(LocalKey.backup_data, {
              statsId,
            });
          }

          Router.push(getSegmentationLink(presentationId));
        } catch {
          createToastError(lexics.api.response.createStatistics.error.default);
        }
      },

      extraOptions: {
        maxRetries: 5,
      },
    }),

    postCommentsArrangements: mutation<
      PostCommentsResponseType,
      { statsId: string; body: Partial<ClientComments> }
    >({
      query: ({ statsId, body }) => {
        return {
          url: `detailing-stats/update/${statsId}`,
          method: 'PATCH',
          body,
        };
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            createToastSuccess(
              lexics.api.response.postCommentsArrangements.success,
            );
          }
        } catch {
          createToastError(lexics.api.response.postCommentsArrangements.error);
        } finally {
          dispatch(setAnswers([]));
        }
      },
      extraOptions: {
        maxRetries: 5,
      },
    }),

    getClientComments: query<ClientComments, string>({
      query: (clientId) => `/detailing-stats/comments-arrangements/${clientId}`,
      providesTags: (_, __, arg) => [{ type: 'ClientComments', id: arg }],
    }),

    getClientOrganisationTypeAll: query<
      GetClientOrganisationTypeAllResponse,
      GetClientOrganisationTypeAllRequest
    >({
      query: () => 'client-organisation-type/all',
    }),

    getVisitTypes: query<GetVisitTypesResponse, GetVisitTypesRequest>({
      query: ({ departmentId }) => {
        return {
          url: `visit-type/${departmentId}`,
        };
      },
    }),

    getTags: query<GetTagsResponse, GetTagsRequest>({
      query: ({ departmentId }) => {
        return {
          url: `tag/${departmentId}`,
        };
      },

      providesTags: (_, __, arg) => [
        {
          type: 'Tags',
          organisationId: arg.departmentId,
        },
      ],
    }),
  }),
});

export const {
  useGetUserQuery,

  useGetOrganizationQuery,
  useLazyGetOrganizationQuery,

  useGetCitiesQuery,

  useGetOrganisationsQuery,

  useGetClientsQuery,
  useLazyGetClientQuery,
  useUpdateClientMutation,
  useGetClientQuery,

  useGetClientOrganizationsQuery,
  useGetClientOrganizationQuery,
  useGetClientOrganizationVisitQuery,
  useLazyGetClientOrganizationVisitQuery,

  useGetPresentationsQuery,
  useGetPresentationQuery,
  useGetPresentationSlidesQuery,

  useCreateStatisticsMutation,

  usePostCommentsArrangementsMutation,
  useGetClientCommentsQuery,

  useGetClientOrganisationTypeAllQuery,
  useLazyGetClientOrganisationTypeAllQuery,

  useGetVisitTypesQuery,

  useGetTagsQuery,
  useLazyGetTagsQuery,
} = api;

export default api;
