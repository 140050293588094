import { ErrorType } from 'reducers/globalErrorSlice';

import { Nullable } from 'types/common';
import { Links } from 'types/links';

export enum TypeButton {
  submit = 'submit',
  button = 'button',
  reset = 'reset',
}

export enum ButtonSize {
  s = 's',
  m = 'm',
}

export enum VariantButton {
  contained = 'contained',
  outlined = 'outlined',
}

export interface PropsButton {
  type: TypeButton;
  children?: React.ReactNode;
  href?: Links | string;
  size?: ButtonSize;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  disabled?: boolean;
  variant?: VariantButton;
  loading?: boolean;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  isSubmitSuccessful?: boolean;
  isError?: Nullable<ErrorType>;
}
